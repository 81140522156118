@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #F1E9D1; /* 背景色を変更 */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.draggable-item {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.draggable-item:active,
.draggable-item.dragging {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.draggable-item.opacity-50 {
  opacity: 0.5;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

button {
  color: white;
}

button:hover {
  opacity: 0.5;
}

.navbar {
  color: black;
  background-color: white;
  position: fixed;
}

.navbar button {
  color: #000000; /* ナビゲーションバー内のボタンの色を変更 */
}

.navbar .menu-item {
  background-color: #70AF97; /* メニューアイテムの背景色を変更 */
  color: white;
}

.navbar .menu-item:hover {
  background-color: #145B5F; /* メニューアイテムのホバー時の背景色を変更 */
}

.purchased-list {
  background-color: #E09982; /* 購入済みリストの背景色を変更 */
  color: white;
}

.purchased-list h3 {
  color: #CF402D; /* 購入済みリストの日付の色を変更 */
}

.fixed-bottom-input {
  padding-bottom: env(safe-area-inset-bottom); /* 安全領域を考慮 */
}

.input-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.draggable-item.edit-mode {
  opacity: 0.6; /* 薄く透過させる */
  animation: shake 0.4s infinite; /* 小刻みに震えるアニメーション */
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-1px);
  }
  75% {
    transform: translateX(1px);
  }
}

.draggable-item.opacity-50 {
  opacity: 0.5;
  transition: opacity 1s ease-in-out; /* スムーズな透過効果 */
}

.form-input {
  margin-right: 0.5rem;
  width: 100%; /* 横幅を100%に設定 */
}

.form-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 1rem;
  display: flex;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

/* 他のスタイルはそのままにしています */
* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
